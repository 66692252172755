import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PayrollService } from '../services/payroll.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
declare var $, PerfectScrollbar, swal: any, Bloodhound: any;
declare function myInput(): any;

@Component({
  selector: 'app-payroll-list',
  templateUrl: './payroll-list.component.html',
  styleUrls: ['./payroll-list.component.scss']
})
export class PayrollListComponent implements OnInit {
  payrollList;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private PayrollService: PayrollService) { }

  @ViewChild(DataBindingDirective, { static: false }) dataBinding: DataBindingDirective;
  public gridData;

  public mySelection: string[] = [];

  public sort: SortDescriptor[] = [{
    field: 'Name',
    dir: 'asc'
  }];

  ngOnInit(): void {
    let todaysdate = this.globals.todaysdate;

    setTimeout(function () {

      $(document).ready(function () {
        const body = document.querySelector('body');
        body.style.setProperty('--screen-height', $(window).height() - 100 + "px");
      });
      new PerfectScrollbar('.content_height');

    }, 100);

    this.globals.isLoading = true;
    this.PayrollService.getAll()
      .then((data) => {
        console.log(data);
        this.payrollList = data;
        this.gridData = data;
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.pageNotfound(error.error.code);
        });
  }

  public onFilter(inputValue: string): void {
    this.payrollList = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'Name',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'AvailableLeave',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'Incentive',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'LeaveTaken',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'Salary',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'SalaryMonth',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'TakeawaySalary',
            operator: 'contains',
            value: inputValue
          }
        ],
      }
    }).data;

    this.dataBinding.skip = 0;
  }

}
