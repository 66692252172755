import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Globals } from './globals';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorClassService } from './http-interceptor-class.service';
import { PayrollListComponent } from './payroll-list/payroll-list.component';

// components
import { LoginComponent } from './login/login.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { PayrollComponent } from './payroll/payroll.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ChartComponent } from './chart/chart.component';
import { ReportComponent } from './report/report.component';

// services
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { PayrollService } from './services/payroll.service';
import { EmployeeService } from './services/employee.service';
import { ConfigurationService } from './services/configuration.service';


const routes: Routes = [

    { path: 'employee/edit/:id', component: EmployeeComponent, canActivate: [AuthGuard] },
    { path: 'employee/add', component: EmployeeComponent, canActivate: [AuthGuard] },    
    { path: 'employee/list', component: EmployeeListComponent, canActivate: [AuthGuard] },
    { path: 'payroll', component: PayrollComponent, canActivate: [AuthGuard] },
    { path: 'payroll/list', component: PayrollListComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
    { path: 'Configuration', component: ConfigurationComponent, canActivate: [AuthGuard] },
    { path: 'chart', component: ChartComponent, canActivate: [AuthGuard] },
    { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
    { path: 'pagenotfound', component: PagenotfoundComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [Globals, AuthService, AuthGuard, EmployeeService, ConfigurationService, PayrollService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorClassService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})

export class AppRoutingModule { }