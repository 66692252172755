<div class="content_block minH">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="white_block content_height">
                    <div class="title_breadcrumb">
                        <h2 class="float-left" style="font-weight: 700;margin: 15px 0px;">PayRoll List</h2>
                        <div class="clearfix"></div>

                    </div>
                    <div class="content_bottom">
                        <kendo-grid [kendoGridBinding]="payrollList" kendoGridSelectBy="id" [selectedKeys]="mySelection"
                            [pageSize]="10" [pageable]="true" [groupable]="false" [reorderable]="false"
                            [resizable]="false" [sortable]="{allowUnsort: true,mode: 'single'}" [sort]="sort">
                            <ng-template kendoGridToolbarTemplate>
                                <input placeholder="Search in all columns..." kendoTextBox
                                    (input)="onFilter($event.target.value)" />
                                <a routerLink="/payroll" class="themeoption_btn float-right btn btn-info text-white">
                                    <span>Add PayRoll</span>
                                </a>
                            </ng-template>
                            <kendo-grid-column field="Name" title="Name">
                            </kendo-grid-column>
                            <kendo-grid-column field="AvailableLeave" title="Available Leave">
                            </kendo-grid-column>
                            <kendo-grid-column field="Incentive" title="Incentive">
                            </kendo-grid-column>
                            <kendo-grid-column field="LeaveTaken" title="LeaveTaken">
                            </kendo-grid-column>
                            <kendo-grid-column field="Salary" title="Salary">
                            </kendo-grid-column>
                            <kendo-grid-column field="SalaryMonth" title="Salary Month">
                            </kendo-grid-column>
                            <kendo-grid-column field="TakeawaySalary" title="Takeaway Salary">
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
