<div class="loginMain">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-12">
        <div class="loginFormMain justify-content-center align-items-center bg-white">
          <div class="row m-0">
            <div class="col-lg-6 p-0">
              <div class="loginBG">
                <img src="assets/images/loginBG.jpg" alt="loginBG" />
              </div>
            </div>
            <div class="col-lg-6 p-0">
              <div class="loginForm d-flex align-items-center justify-content-center p-5 h-100 text-center">
                <form #loginForm="ngForm" (ngSubmit)="login(loginForm)" class="w-100">
                  <h2 class="mb-4">Login<span>to your account</span></h2>
                  <div class="form-group">
                    <input type="text" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" maxlength="100"
                      class="form-control no-bg" #EmailAddress="ngModel" [(ngModel)]="loginEntity.EmailAddress"
                      name="EmailAddress" placeholder="Enter email"
                      [ngClass]="{red_border: (EmailAddress.dirty && EmailAddress.invalid) || (EmailAddress.pristine && submitted)}"
                      required />
                    <span class="error_span"
                      *ngIf="(EmailAddress.dirty && EmailAddress.invalid) || (EmailAddress.pristine && submitted) || errorEntity.EmailAddress">
                      <span
                        *ngIf="(EmailAddress.errors && EmailAddress.errors.required) || (EmailAddress.pristine && submitted) || errorEntity.EmailAddress">Please
                        enter email address</span>
                      <span *ngIf="EmailAddress.errors && EmailAddress.errors.pattern">Please enter valid
                        email
                        address</span>
                    </span>
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control" #Password="ngModel" maxlength="100"
                      [(ngModel)]="loginEntity.Password" name="Password" placeholder="Enter password"
                      [ngClass]="{red_border: (Password.dirty && Password.invalid) || (Password.pristine && submitted)}"
                      required />
                    <span class="error_span"
                      *ngIf="(Password.dirty && Password.invalid) || (Password.pristine && submitted) || errorEntity.Password">
                      <span
                        *ngIf="(Password.errors && Password.errors.required) || (Password.pristine && submitted) || errorEntity.Password">Please
                        enter
                        password</span>
                    </span>
                  </div>
                  <button type="submit" class="btn btn-primary w-100">login</button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<style>
  span.error_span {
    color: red;
    font-size: 10px;
  }
</style>