
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="payollFormMain">
                <form role="form" #payrollForm="ngForm" (ngSubmit)="generateChart(payrollForm)">
                    <div class="form-group">
                        <div class="eName">
                            <label for="eName"><span>*</span> {{globals.payrollTranslationText.payroll.form.employeeName.label}}:</label>
                            <kendo-dropdownlist [data]="defaultEntity" [filterable]="false" 
                                [textField]="'Name'" [valueField]="'Id'" [valuePrimitive]="true" 
                                #EmployeeId="ngModel" [(ngModel)]="payrollEntity.EmployeeId" name="EmployeeId"
                                [ngClass]="{error: (EmployeeId.invalid) || (EmployeeId.pristine && submitted)}"
                                required></kendo-dropdownlist>
                                <span class="error_span"
                                    *ngIf="(EmployeeId.dirty && EmployeeId.invalid) || (EmployeeId.pristine && submitted)">
                                    <span *ngIf="EmployeeId.errors && EmployeeId.errors.required">
                                        {{globals.payrollTranslationText.payroll.form.employeeName.required}}
                                    </span>
                                </span>
                        </div>
                       
                    </div>
                    <div class="form-group">
                        <label><span>* </span>{{globals.payrollTranslationText.payroll.form.date.label}}: </label>
                            <kendo-daterange>
                                <label class="mr-3 mr-lg-2">
                                <span class="label">Start</span>
                                <kendo-dateinput kendoDateRangeStartInput name="FromDate" #FromDate="ngModel"
                                  [(ngModel)]="payrollEntity.FromDate" (blur) ="dateValid = false"></kendo-dateinput>
                              </label>
                              <label>
                                <span class="label">End</span>
                                <kendo-dateinput kendoDateRangeEndInput name="ToDate" #ToDate="ngModel"
                                  [(ngModel)]="payrollEntity.ToDate"></kendo-dateinput>
                              </label>
                            </kendo-daterange>
                        <span class="error_span" *ngIf="dateValid">
                            <span *ngIf="dateValid">
                                {{globals.payrollTranslationText.payroll.form.date.required}}
                            </span>
                        </span>
                        <!-- <span class="error_span error" *ngIf="(FromDate.dirty && FromDate.invalid) || (FromDate.pristine && submitted)">
                                {{globals.adminTranslationText.leave.form.FromDate.required}}</span> -->
                    </div>
                    <div class="d-flex">
                        <button type="submit" class="btn btn-success">{{globals.payrollTranslationText.payroll.form.buttons.calculate}}</button>
                        
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<script>
    $(document).ready(function () {
        // create DatePicker from input HTML element
        $("#datepicker").kendoDatePicker();

        $("#monthpicker").kendoDatePicker({
            // defines the start view
            start: "year",

            // defines when the calendar should return date
            depth: "year",

            // display month and year in the input
            format: "MMMM yyyy",

            // specifies that DateInput is used for masking the input element
            dateInput: true
        });
    });
</script>
<div class="col-md-12 col-xl-6 col-lg-12 col-12 col-sm-12" style="padding-top: 150px;">
    <div class="chart_block monthly_proctor">
      <h5 class="float-left"><b>Salary Chart</b></h5>
         <div class="clearfix"></div>
      
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-month-jan-mar" role="tabpanel"
          aria-labelledby="nav-month-jan-mar-tab">
          <div id="chartdiv" style="width: 100%; height: 500px"></div>
        </div>
        <div class="tab-pane fade" id="nav-month-apr-june" role="tabpanel"
          aria-labelledby="nav-month-apr-june-tab">...</div>
        <div class="tab-pane fade" id="nav-month-jul-sept" role="tabpanel"
          aria-labelledby="nav-month-jul-sept-tab">...</div>
        <div class="tab-pane fade" id="nav-month-oct-dec" role="tabpanel"
          aria-labelledby="nav-month-oct-dec-tab">...</div>
      </div>
    </div>
  </div>