import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { Globals } from '../globals';
import { JwtHelperService } from '@auth0/angular-jwt';

declare var $: any, PerfectScrollbar, swal;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private AuthService: AuthService, private router: Router, public globals: Globals) { }

  ngOnInit() {

  }

  logout(){
    this.globals.authData = '';
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
