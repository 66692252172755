<div class="container-fluid login_register_bg">
  <div class="loginregister_white_block">
    <div class="row justify-content-around">
      <div class="col-lg-12">
        <div class="page_found_block text-center">
          <h6>Page Not Found!</h6>
        </div>
      </div>
    </div>
  </div>
</div>