import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../services/employee.service';
declare var $, swal: any, PerfectScrollbar;

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  employeeEntity;
  submitted;
  btn_disable;
  Id;
  errorEntity;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private EmployeeService: EmployeeService) { }

  ngOnInit(): void {
    this.globals.isLoading = false;
    this.errorEntity = {};
    setTimeout(function () {

      $(document).ready(function () {
        const body = document.querySelector('body');
        body.style.setProperty('--screen-height', $(window).height() - 100 + "px");
      });
      new PerfectScrollbar('.content_height');

    }, 100);

    let id = this.route.snapshot.paramMap.get('id');

    if (id) {
      debugger
      id = window.atob(id);
      this.globals.isLoading = true;
      this.EmployeeService.getById(id)
        .then((data) => {
          this.employeeEntity = data;
          this.employeeEntity.JoinDate = new Date(this.employeeEntity.JoinDate);
          this.globals.isLoading = false;
        },
          (error) => {
            this.globals.isLoading = false;
            this.router.navigate(['/pagenotfound']);
          });
    }
    else {
      this.employeeEntity = {};
      this.employeeEntity.Id = 0;
      this.employeeEntity.IsActive = 1;
    }
  }

  addUpdate(employeeForm) {
    debugger
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.submitted = false;
    } else {
      this.submitted = true;
    }
    var d = new Date(this.employeeEntity.JoinDate);
    var ValidFromMonth = d.getMonth() + 1;
    var ValidFromDate = d.getDate();
    var ValidFromYear = d.getFullYear();
    this.employeeEntity.JoinDate = ValidFromYear + '-' + (ValidFromMonth < 10 ? '0' + ValidFromMonth : '' + ValidFromMonth) + '-' + ((ValidFromDate < 10 ? '0' + ValidFromDate : '' + ValidFromDate));
    this.employeeEntity.IsActive = 1;

    if (employeeForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.EmployeeService.addUpdate(this.employeeEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted = false;
          this.employeeEntity = {};
          employeeForm.form.markAsPristine();
          if (id) {
            swal({
              type: this.globals.employeeTranslationText.employee.form.alerts.update.type,
              title: this.globals.employeeTranslationText.employee.form.alerts.update.title,
              text: this.globals.employeeTranslationText.employee.form.alerts.update.text,
              showConfirmButton: false,
              timer: 2000
            })
          } else {
            swal({
              type: this.globals.employeeTranslationText.employee.form.alerts.add.type,
              title: this.globals.employeeTranslationText.employee.form.alerts.add.title,
              text: this.globals.employeeTranslationText.employee.form.alerts.add.text,
              showConfirmButton: false,
              timer: 2000
            })
          }

          this.router.navigate(['/employee/list']);
        },
          (error) => {
            this.globals.isLoading = false;
            this.btn_disable = false;
            if (error.error.message == 'Dublicate Employee Id') {
              swal({
                type: this.globals.employeeTranslationText.employee.form.alerts.duplicateEmployeeId.type,
                title: this.globals.employeeTranslationText.employee.form.alerts.duplicateEmployeeId.title,
                text: this.globals.employeeTranslationText.employee.form.alerts.duplicateEmployeeId.text,
              })
            } else if (error.error.message == 'Duplicate Email') {
              swal({
                type: this.globals.employeeTranslationText.employee.form.alerts.duplicateEmail.type,
                title: this.globals.employeeTranslationText.employee.form.alerts.duplicateEmail.title,
                text: this.globals.employeeTranslationText.employee.form.alerts.duplicateEmail.text,
              })
            } else if (error.status == 422) {
             
              this.errorEntity.Email = (error.error.message.Email != "") ? error.error.message.Email : '';
              this.errorEntity.EmployeeId = (error.error.message.EmployeeId != "") ? error.error.message.EmployeeId : '';
              this.errorEntity.Name = (error.error.message.Name != "") ? error.error.message.Name : '';
              this.errorEntity.JoinDate = (error.error.message.JoinDate != "") ? error.error.message.JoinDate : '';
            } else {
              this.router.navigate(['/pagenotfound']);
            }
          });
    }
  }
}

