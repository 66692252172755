<div class="content_block minH">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="white_block content_height">
                    <div class="title_breadcrumb">
                        <h2 class="float-left" style="font-weight: 700;margin: 15px 0px;">
                            {{globals.employeeTranslationText.employee.list.title}}</h2>
                        <div class="clearfix"></div>
                        <!-- <a routerLink="/employee/add" class="themeoption_btn float-right">
                            <span>{{globals.employeeTranslationText.employee.list.buttons.add}}</span>
                        </a> -->
                    </div>
                    <div class="content_bottom">
                        <kendo-grid [kendoGridBinding]="employeeList" kendoGridSelectBy="id"
                            [selectedKeys]="mySelection" [pageSize]="10" [pageable]="true" [groupable]="false"
                            [reorderable]="false" [resizable]="false" [sortable]="{allowUnsort: true,mode: 'single'}"
                            [sort]="sort">
                            <ng-template kendoGridToolbarTemplate>
                                <input placeholder="Search in all columns..." kendoTextBox
                                    (input)="onFilter($event.target.value)" />
                                <a routerLink="/employee/add"
                                    class="themeoption_btn float-right btn btn-info text-white">
                                    <span>{{globals.employeeTranslationText.employee.list.buttons.add}}</span>
                                </a>
                            </ng-template>
                            <kendo-grid-column field="Name"
                                title="{{globals.employeeTranslationText.employee.list.collumns.Name}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="Email"
                                title="{{globals.employeeTranslationText.employee.list.collumns.Email}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="JoinDate"
                                title="{{globals.employeeTranslationText.employee.list.collumns.JoinDate}}">
                            </kendo-grid-column>
                            <kendo-grid-column field="EmployeeId"
                                title="{{globals.employeeTranslationText.employee.list.collumns.EmployeeId}}">
                            </kendo-grid-column>
                            <kendo-grid-column
                                title="{{globals.employeeTranslationText.employee.list.collumns.actions}}" width="150">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                                    <button kendoButton class="edit" (click)="editemployee(dataItem.Id)">
                                        <i class="fa fa-edit" data-toggle="tooltip" data-placement="top"
                                            title="Edit"></i>
                                    </button>
                                    <button kendoButton kendoGridRemoveCommand [disabled]="dataItem.isdisabled>0"
                                        class="delete ml-3" (click)="deleteItem(dataItem)">
                                        <i class="fa fa-trash" data-toggle="tooltip" data-placement="top"></i>
                                    </button>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>

