<div class="payrollFormMain">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="formMain">
                    <h2 class="m-0">Payroll Details</h2>
                    <form role="form" #payrollForm="ngForm" (ngSubmit)="addPayroll(payrollForm)" class="form-validate p-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="eName"><span>*</span>
                                        {{globals.payrollTranslationText.payroll.form.employeeName.label}}:</label>
                                    <kendo-dropdownlist [data]="defaultEntity" [filterable]="false" [textField]="'Name'"
                                        [valueField]="'Id'" [valuePrimitive]="true" #EmployeeId="ngModel"
                                        [(ngModel)]="payrollEntity.EmployeeId" name="EmployeeId" class="form-control"
                                        [ngClass]="{error: (EmployeeId.invalid) || (EmployeeId.pristine && submitted)}"
                                        required (valueChange)="CalculateLeave()"></kendo-dropdownlist>
                                    <span class="error_span"
                                        *ngIf="(EmployeeId.dirty && EmployeeId.invalid) || (EmployeeId.pristine && submitted)">
                                        <span *ngIf="EmployeeId.errors && EmployeeId.errors.required">
                                            {{globals.payrollTranslationText.payroll.form.employeeName.required}}
                                        </span>
                                    </span>
                                    <!-- <div class="eName">
                                            <div class="row">
                                                <div class="col-lg-10">
                                                    <kendo-dropdownlist [data]="defaultEntity" [filterable]="false"
                                                        [textField]="'Name'" [valueField]="'Id'" [valuePrimitive]="true"
                                                        #EmployeeId="ngModel" [(ngModel)]="payrollEntity.EmployeeId"
                                                        name="EmployeeId" class="form-control"
                                                        [ngClass]="{error: (EmployeeId.invalid) || (EmployeeId.pristine && submitted)}"
                                                        required></kendo-dropdownlist>
                                                    <span class="error_span"
                                                        *ngIf="(EmployeeId.dirty && EmployeeId.invalid) || (EmployeeId.pristine && submitted)">
                                                        <span *ngIf="EmployeeId.errors && EmployeeId.errors.required">
                                                            {{globals.payrollTranslationText.payroll.form.employeeName.required}}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="col-lg-2">
                                                    <button type="button" (click)="addEmployee()"
                                                        class="btn btn-secondary rounded-1">{{globals.payrollTranslationText.payroll.form.buttons.addEmployee}}</button>
                                                </div>
                                            </div>
                                        </div> -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label><span>* </span>{{globals.payrollTranslationText.payroll.form.date.label}}:
                                    </label>
                                    <kendo-datepicker #SalaryMonth="ngModel" [(ngModel)]="payrollEntity.SalaryMonth"
                                        name="SalaryMonth" id="SalaryMonth" [ngClass]="{'error': salaryMonthValid}" [min]="minw"
                                        [format]="'MMMM yyyy'" class="form-control" readonly (valueChange)="CalculateLeave()"></kendo-datepicker>
                                    <span class="error_span" *ngIf="salaryMonthValid">
                                        <span *ngIf="salaryMonthValid">
                                            {{globals.payrollTranslationText.payroll.form.date.required}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="leaveTaken"><span>*</span>
                                        {{globals.payrollTranslationText.payroll.form.salary.label}}:</label>
                                    <input class="form-control" type="text"
                                        placeholder="{{globals.payrollTranslationText.payroll.form.salary.label}}"
                                        #Salary="ngModel" [(ngModel)]="payrollEntity.Salary"
                                        [ngClass]="{error: (Salary.dirty && Salary.invalid) || (Salary.pristine && submitted) || (Salary.errors && Salary.errors.required)}"
                                        name="Salary" id="Salary" pattern="^\d{0,10}(\.\d{1,2})?$" (focusout)="calculatePayroll()" required>
                                    <span class="error_span"
                                        *ngIf="(Salary.dirty && Salary.invalid) || (Salary.pristine && submitted)">
                                        <span
                                            *ngIf="(Salary.errors.required) || (Salary.pristine && submitted)">{{globals.payrollTranslationText.payroll.form.salary.required}}</span>
                                        <span
                                            *ngIf="Salary.errors && Salary.errors.pattern">{{globals.payrollTranslationText.payroll.form.salary.pattern}}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="incentive"> <span>*</span>
                                        {{globals.payrollTranslationText.payroll.form.incentive.label}}:</label>
                                    <input class="form-control" type="text"
                                        placeholder="{{globals.payrollTranslationText.payroll.form.incentive.label}}"
                                        #Incentive="ngModel" [(ngModel)]="payrollEntity.Incentive"
                                        [ngClass]="{error: (Incentive.dirty && Incentive.invalid) || (Incentive.pristine && submitted)}"
                                        name="Incentive" id="Incentive" pattern="^\d{0,10}(\.\d{1,2})?$" (focusout)="calculatePayroll()" required>
                                    <span class="error_span"
                                        *ngIf="(Incentive.dirty && Incentive.invalid) || (Incentive.pristine && submitted)">
                                        <span
                                            *ngIf="(Incentive.errors.required) || (Incentive.pristine && submitted)">{{globals.payrollTranslationText.payroll.form.incentive.required}}</span>
                                        <span
                                            *ngIf="Incentive.errors && Incentive.errors.pattern">{{globals.payrollTranslationText.payroll.form.incentive.pattern}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="leaveTaken"><span>*</span>
                                        {{globals.payrollTranslationText.payroll.form.leaveTaken.label}}:</label>
                                    <input class="form-control" type="text"
                                        placeholder="{{globals.payrollTranslationText.payroll.form.leaveTaken.label}}"
                                        #LeaveTaken="ngModel" [(ngModel)]="payrollEntity.LeaveTaken"
                                        [ngClass]="{error: (LeaveTaken.dirty && LeaveTaken.invalid) || (LeaveTaken.pristine && submitted) || (LeaveTaken.errors && LeaveTaken.errors.required)}"
                                        name="LeaveTaken" id="LeaveTaken" pattern="^\d{0,2}(\.\d{1,1})?$" (focusout)="CalculateLeave()" required>
                                    <span class="error_span"
                                        *ngIf="(LeaveTaken.dirty && LeaveTaken.invalid) || (LeaveTaken.pristine && submitted)">
                                        <span
                                            *ngIf="(LeaveTaken.errors.required) || (LeaveTaken.pristine && submitted)">{{globals.payrollTranslationText.payroll.form.leaveTaken.required}}</span>
                                        <span
                                            *ngIf="LeaveTaken.errors && LeaveTaken.errors.pattern">{{globals.payrollTranslationText.payroll.form.leaveTaken.pattern}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <h5 class="mt-3 mb-1">
                                    <strong>{{globals.payrollTranslationText.payroll.form.leaveTitle}}:</strong>
                                </h5>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>{{globals.payrollTranslationText.payroll.form.totalPLBalance}}:</label>
                                    <div class="form-control readOnly">{{payrollEntity.plBalance}}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>{{globals.payrollTranslationText.payroll.form.availablePLBalance}}:</label>
                                    <div class="form-control readOnly">{{payrollEntity.AvailableLeave}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <h5 class="mt-3 mb-1">
                                        <strong>{{globals.payrollTranslationText.payroll.form.esicDeduction}}:</strong>
                                    </h5>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label>{{globals.payrollTranslationText.payroll.form.employeeDeduction}}:</label>
                                            <div class="form-control readOnly">{{payrollEntity.Employee}}</div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label>{{globals.payrollTranslationText.payroll.form.employerDeduction}}:</label>
                                            <div class="form-control readOnly">{{payrollEntity.Employer}}</div>
                                        </div>
                                        <div class="col-lg-4">
                                            <label>{{globals.payrollTranslationText.payroll.form.totalEsic}}:</label>
                                            <div class="form-control readOnly">{{payrollEntity.totalEsic}}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col--md-6">
                                <div class="form-group">
                                    <label>{{globals.payrollTranslationText.payroll.form.professionalTax}}:</label>
                                    <div class="form-control readOnly">{{payrollEntity.ProfessionalTax}}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col--md-6">
                                <div class="form-group">
                                    <label
                                        for="pTax">{{globals.payrollTranslationText.payroll.form.totalEaringSalary}}:</label>
                                    <div class="form-control readOnly">{{payrollEntity.TakeawaySalary}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="formPayrollBottom d-flex justify-content-between">
                            <button type="submit"
                                class="btn btn-success">{{globals.payrollTranslationText.payroll.form.buttons.calculate}}</button>
                            <button type="button" (click)="clearData(payrollForm)"
                                class="btn btn-secondary">{{globals.payrollTranslationText.payroll.form.buttons.clear}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    $(document).ready(function () {
        // create DatePicker from input HTML element
        $("#datepicker").kendoDatePicker();

        $("#monthpicker").kendoDatePicker({
            // defines the start view
            start: "year",

            // defines when the calendar should return date
            depth: "year",

            // display month and year in the input
            format: "MMMM yyyy",

            // specifies that DateInput is used for masking the input element
            dateInput: true
        });
    });
</script>