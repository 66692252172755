import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';

import employeeTranslationText from "../assets/data/employeeTranslationText.json";
import payrollTranslationText from "../assets/data/payrollTranslationText.json";

@Injectable()
export class Globals {

  constructor() { }

  baseAPIUrl: string = environment.apiUrl;
  baseUrl: string = environment.baseUrl;
  headerpath: string = "{'Content-Type': 'application/json','Accept': 'application/json'}";
  IsLoggedIn: boolean = false;
  isLoading: boolean = false;
  currentLink: string = '';
  currentModule: string = '';
  authData = localStorage.getItem('token') ? localStorage.getItem('token') : null;
  check_login = false;
  todaysdate: string = '';
  WebsiteUrl = 'oeti';
  previousLink: string = '';
  employeeTranslationText = employeeTranslationText;
  payrollTranslationText = payrollTranslationText;
  pageNotfound(code)
  {
    window.location.href = 'pagenotfound/';
  }
}
