import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../services/employee.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
declare var $, PerfectScrollbar, swal: any, Bloodhound: any;
declare function myInput(): any;


@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  employeeList;
  deleteEntity;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private EmployeeService: EmployeeService) { }

  @ViewChild(DataBindingDirective, { static: false }) dataBinding: DataBindingDirective;
  public gridData;

  public mySelection: string[] = [];

  public sort: SortDescriptor[] = [{
    field: 'Name',
    dir: 'asc'
  }];

  ngOnInit(): void {
    let todaysdate = this.globals.todaysdate;

    setTimeout(function () {

      $(document).ready(function () {
        const body = document.querySelector('body');
        body.style.setProperty('--screen-height', $(window).height() - 100 + "px");
      });
      new PerfectScrollbar('.content_height');

    }, 100);

    this.globals.isLoading = true;
    this.EmployeeService.getAll()
      .then((data) => {
        console.log(data);
        this.employeeList = data;
        this.gridData = data;
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.pageNotfound(error.error.code);
        });
  }

  deleteItem(employee) {
    debugger
    this.deleteEntity = employee;
    swal({
      type: this.globals.employeeTranslationText.employee.list.alerts.deleteConfirm.type,
      title: this.globals.employeeTranslationText.employee.list.alerts.deleteConfirm.title + ' - ' + employee.Name,
      text: this.globals.employeeTranslationText.employee.list.alerts.deleteConfirm.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
      .then((result) => {
        if (result.value) {

          employee.Id = employee.Id;
          employee.TableName = 'tblemployee';
          employee.FieldName = 'Id';

          this.globals.isLoading = true;
          this.EmployeeService.deleteItem(employee)
            .then((data) => {
              this.globals.isLoading = false;
              var employeeList = [...this.employeeList];
              let index = employeeList.indexOf(employee);
              if (index != -1) {
                employeeList.splice(index, 1);
              }
              this.employeeList = [...employeeList];
              this.gridData = this.employeeList;
              swal({
                type: this.globals.employeeTranslationText.employee.list.alerts.deleteSuccess.type,
                title: this.globals.employeeTranslationText.employee.list.alerts.deleteSuccess.title,
                text: this.globals.employeeTranslationText.employee.list.alerts.deleteSuccess.text,
                showConfirmButton: false,
                timer: 2000
              });
            },
              (error) => {
                this.globals.isLoading = false;
                swal({
                  // type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                  // title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                  // text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  showConfirmButton: false,
                  timer: 4000
                })
              });
        }
      })
  }

  public onFilter(inputValue: string): void {
    this.employeeList = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'Name',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'Email',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'JoinDate',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'EmployeeId',
            operator: 'contains',
            value: inputValue
          }
        ],
      }
    }).data;

    this.dataBinding.skip = 0;
  }

  editemployee(id) {
    this.router.navigate(['/employee/edit/' + window.btoa(id)]);
  }

}
