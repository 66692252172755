<div class="loader_new">
    <!-- <div class="loader_new" *ngIf="globals.isLoading"> -->
    <div class="lds-ellipsis">
        <!-- <img src="assets/images/loader.gif"> -->
    </div>
    <!-- <div class="lds-ellipsis_text"><strong>Processing...</strong></div> -->
</div>

<app-header *ngIf="globals.authData!=undefined"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="globals.authData!=undefined"></app-footer>