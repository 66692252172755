<header>
    <nav class="navbar navbar-expand-sm bg-dark navbar-dark p-0">
        <!-- Brand/logo -->
        <a class="navbar-brand ml-3" href="#">
            <img src="assets/images/logo.png" alt="logo" />
        </a>

        <!-- Links -->
        <ul class="navbar-nav ml-auto p-0">
            <li class="nav-item">
                <a class="nav-link" routerLink="/employee/list">
                    Employee
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/payroll/list">
                    Payroll
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/report">
                    Report
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/Configuration">
                    Configuration
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link logout" href="#" (click)="logout();">
                    <i class="fas fa-sign-out-alt"></i>
                    Logout
                </a>
            </li>
        </ul>
    </nav>
</header>