import { Injectable, Inject } from '@angular/core';
import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Router, RoutesRecognized } from '@angular/router';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { filter, pairwise } from 'rxjs/operators';
declare var $, swal: any;


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, public globals: Globals) { }

  canActivate(route, state: RouterStateSnapshot) {
    debugger

    this.globals.currentLink = state.url;
    if (this.authService.isLoggedIn() == true) {
      if(state.url == '/login'){
        this.globals.IsLoggedIn = true;
        this.router.navigate(['/payroll']);
        return false;
      } else {
        this.globals.IsLoggedIn = true;
        return true;
      }      
    } else {
      if (state.url == '/login') {
    		this.globals.IsLoggedIn = false;
    		return true;
    	} else {
    		this.globals.IsLoggedIn = false;
    		this.router.navigate(['/login']);
    		return false;
      }
    } 
    //return true;
  }
}

