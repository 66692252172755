<div class="employeeFormMain">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="formMain">
                    <h2 class="m-0">Employee Details</h2>
                    <form class="form-validate p-4" role="form" #employeeForm="ngForm"
                        (ngSubmit)="addUpdate(employeeForm)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="e_Name"> <span>*
                                        </span>{{globals.employeeTranslationText.employee.form.emaployeeName.label}}</label>
                                    <input type="text" class="form-control" #Name="ngModel"
                                        [(ngModel)]="employeeEntity.Name" name="Name" pattern="^[a-zA-Z][\sa-zA-Z]{2,}"
                                        placeholder="{{globals.employeeTranslationText.employee.form.emaployeeName.label}}"
                                        [ngClass]="{error: (Name.dirty && Name.invalid) || (Name.pristine && submitted)}"
                                        maxlength="50" required>
                                    <span class="error_span error"
                                        *ngIf="(Name.dirty && Name.invalid) || (Name.pristine && submitted)">
                                        <span *ngIf="Name.errors && Name.errors.required">
                                            {{globals.employeeTranslationText.employee.form.emaployeeName.required}}
                                        </span>
                                        <span *ngIf="Name.errors && Name.errors.pattern">
                                            {{globals.employeeTranslationText.employee.form.emaployeeName.pattern}}
                                        </span>
                                    </span>
                                    <span class="error_span error" *ngIf="errorEntity.Name">
                                        <span>{{ errorEntity.Name }}</span>
                                      </span>
                                   
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="e_ID"> <span>*
                                        </span>{{globals.employeeTranslationText.employee.form.emaployeeID.label}}</label>
                                    <input type="text" class="form-control" #EmployeeId="ngModel"
                                        [(ngModel)]="employeeEntity.EmployeeId" name="EmployeeId" pattern="[0-9]{1,}"
                                        placeholder="{{globals.employeeTranslationText.employee.form.emaployeeID.label}}"
                                        [ngClass]="{error: (EmployeeId.dirty && EmployeeId.invalid) || (EmployeeId.pristine && submitted)}"
                                        maxlength="10" required>
                                    <span class="error_span error"
                                        *ngIf="(EmployeeId.dirty && EmployeeId.invalid) || (EmployeeId.pristine && submitted)">
                                        <span *ngIf="EmployeeId.errors && EmployeeId.errors.required">
                                            {{globals.employeeTranslationText.employee.form.emaployeeID.required}}
                                        </span>
                                        <span *ngIf="Name.errors && Name.errors.pattern">
                                            {{globals.employeeTranslationText.employee.form.emaployeeID.pattern}}
                                        </span>
                                    </span>
                                    <span class="error_span error" *ngIf="errorEntity.EmployeeId">
                                        <span>{{ errorEntity.EmployeeId }}</span>
                                      </span>
                                      
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label><span>*
                                        </span>{{globals.employeeTranslationText.employee.form.dateOfJoining.label}}</label>
                                    <kendo-datepicker [(ngModel)]="employeeEntity.JoinDate" #JoinDate="ngModel"
                                        [format]="'MM/dd/yyyy'" name="JoinDate" id="JoinDate" class="form-control"
                                        [ngClass]="{'error': (JoinDate.dirty && JoinDate.invalid) || (JoinDate.pristine && submitted)}"
                                        required>
                                    </kendo-datepicker>
                                    <span class="error_span error"
                                        *ngIf="(JoinDate.dirty && JoinDate.invalid) || (JoinDate.pristine && submitted)">
                                        {{globals.employeeTranslationText.employee.form.dateOfJoining.required}}
                                    </span>
                                        <span class="error_span error" *ngIf="errorEntity.JoinDate">
                                            <span>{{ errorEntity.JoinDate }}</span>
                                        </span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="email"><span>*
                                        </span>{{globals.employeeTranslationText.employee.form.emailAddress.label}}</label>
                                    <input type="email" class="form-control"
                                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" #Email="ngModel"
                                        placeholder="{{globals.employeeTranslationText.employee.form.emailAddress.label}}"
                                        [(ngModel)]="employeeEntity.Email" name="Email" maxlength="100"
                                        [ngClass]="{error: (Email.dirty && Email.invalid) || (Email.pristine && submitted)}"
                                        required />
                                    <span class="error_span error"
                                        *ngIf="(Email.dirty && Email.invalid) || (Email.pristine && submitted)">
                                        <span *ngIf="Email.errors && Email.errors.required">
                                            {{globals.employeeTranslationText.employee.form.emailAddress.required}}
                                        </span>
                                        <span *ngIf="Email.errors && Email.errors.pattern">
                                            {{globals.employeeTranslationText.employee.form.emailAddress.pattern}}
                                        </span>
                                    </span>
                                    <span class="error_span error" *ngIf="errorEntity.Email">
                                        <span>{{ errorEntity.Email }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="formBottom">
                            <button type="submit" class="btn btn-success"><span
                                    *ngIf="employeeEntity.Id == 0">{{globals.employeeTranslationText.employee.form.buttons.add}}</span>
                                <span
                                    *ngIf="employeeEntity.Id != 0">{{globals.employeeTranslationText.employee.form.buttons.update}}</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    $(document).ready(function () {
        // create DatePicker from input HTML element
        $("#datepicker").kendoDatePicker();

        $("#monthpicker").kendoDatePicker({
            // defines the start view
            start: "year",

            // defines when the calendar should return date
            depth: "year",

            // display month and year in the input
            format: "MMMM yyyy",

            // specifies that DateInput is used for masking the input element
            dateInput: true
        });
    });
</script>