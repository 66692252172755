import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PayrollService } from '../services/payroll.service';
declare var $, swal: any;

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  defaultEntity;
  payrollEntity;
  configurationData;
  errorEntity;
  submitted;
  salaryMonthValid;
  minw;
  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private PayrollService: PayrollService) { }

  ngOnInit() {
    this.defaultEntity = [];
    this.payrollEntity = {};
    this.errorEntity = {};
    this.salaryMonthValid = false;
    this.payrollEntity.AvailableLeave = 0;
    this.payrollEntity.plBalance = 0;
    this.payrollEntity.Employee = 0;
    this.payrollEntity.Employer = 0;
    this.payrollEntity.totalEsic = 0;
    this.payrollEntity.ProfessionalTax = 0;
    this.payrollEntity.TakeawaySalary = 0;
    this.minw = new Date();
    //this.defaultEntity.Id = '';
    //this.payrollEntity.EmployeeID = '';
    this.configurationData = {};

    //this.payrollEntity.SalaryMonth = '2020/7/30';
    //this.CalculateDays('2020/1/1', '2020/7/30');


    //get all default data
    this.globals.isLoading = true;
    this.PayrollService.getDefaultData()
      .then((data) => {
        var defaultdata: any;
        defaultdata = data;
        console.log(defaultdata);
        var defaultSelect = {
          Id: "",
          Name: this.globals.payrollTranslationText.payroll.form.employeeName.select,
          Value: ""
        };
        this.defaultEntity.push(defaultSelect);
        this.defaultEntity = [...this.defaultEntity, ...defaultdata];
        this.payrollEntity.EmployeeID = '';
        console.log(this.payrollEntity);
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.pageNotfound(error.error.code);
        });
  }

  showDays(firstDate, secondDate) {
    debugger

    const timeDiff = new Date(secondDate).getTime() - new Date(firstDate).getTime();
    let days = timeDiff / (1000 * 60 * 60 * 24);
    let months = timeDiff / (1000 * 60 * 60 * 24 * 30);
    months = parseInt(months.toString());


    let plBalance = 0;
    if (months <= 6) {
      plBalance = 1 * months;
    } else if (months > 6 && months <= 12) {
      plBalance = 1.5 * months;
    } else if (months > 12 && months <= 36) {
      plBalance = plBalance + 5;
      let year = months / 12;
      if (year == 0) {
        plBalance = plBalance;
      } else if (year == 1) {
        plBalance = plBalance + (months - 12) * 1.5;
      } else if (year == 2) {
        plBalance = plBalance + (months - 24) * 1.5;
      } else if (year == 3) {
        plBalance = plBalance + (months - 36) * 1.5;
      }
    } else if (months > 36 && months <= 60) {
      plBalance = plBalance + 6;
      let year = months / 12;
      if (year == 3) {
        plBalance = plBalance + (months - 36) * 1.5;
      } else if (year == 4) {
        plBalance = plBalance + (months - 48) * 1.5;
      } else if (year == 5) {
        plBalance = plBalance + (months - 60) * 1.5;
      }
    } else if (months > 60) {
      plBalance = plBalance + 7;
      let leftMonth = months % 12;
      plBalance = plBalance + leftMonth * 1.5;
    }


    // if(days > 364){
    //   if(days > 364 && days < 1125){
    //     plBalance = plBalance + 5;
    //     if(months ){

    //     }
    //     //days = days - ;
    //   } else if(days > 1125 && days < 1855){
    //     plBalance = plBalance + 6;
    //   } else if(days > 1855) {
    //     plBalance = plBalance + 7;
    //   }

    //}

    // var Difference_In_Time =  new Date().getTime()- this.payrollEntity.SalaryMonth.getTime();
    // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    // alert(Difference_In_Days);

  }

  // CalculateLeave() {
  //   debugger

  //   if (this.payrollEntity.SalaryMonth != undefined) {
  //     var d = new Date(this.payrollEntity.SalaryMonth);
  //     var Month = d.getMonth() + 1;
  //     // var date = d.getDate();
  //     var date = 1;
  //     var Year = d.getFullYear();
  //     this.payrollEntity.SalaryDate =
  //       Year +
  //       "/" +
  //       (Month < 10
  //         ? "0" + Month
  //         : "" + Month) +
  //       "/" +
  //       (date < 10 ? "0" + date : "" + date);
  //   }

  //   this.defaultEntity.forEach(element => {
  //     if (element.Id == this.payrollEntity.EmployeeId) {
  //       this.payrollEntity.JoinDate = element.JoinDate;
  //     }
  //   });
  //   this.minw = new Date(this.payrollEntity.JoinDate);

  //   if (this.payrollEntity.EmployeeId > 0 && this.payrollEntity.SalaryDate != undefined) {

  //     let plBalance = this.CalculateDays(this.payrollEntity.JoinDate, this.payrollEntity.SalaryDate);
  //     let LeaveTaken = this.payrollEntity.LeaveTaken >= 0 ? this.payrollEntity.LeaveTaken : 0;
  //     this.payrollEntity.AvailableLeave = plBalance - LeaveTaken;
  //   }

CalculateLeave(){ debugger

  if (this.payrollEntity.SalaryMonth != undefined) {
    var d = new Date(this.payrollEntity.SalaryMonth);
    var Month = d.getMonth() + 1;
    // var date = d.getDate();
    var date = 1;
    var Year = d.getFullYear();
    this.payrollEntity.SalaryDate =
      Year +
      "/" +
      (Month < 10
        ? "0" + Month
        : "" + Month) +
      "/" +
      (date < 10 ? "0" + date : "" + date);
      this.salaryMonthValid = false;
  } 
  
  this.defaultEntity.forEach(element => {
    if(element.Id == this.payrollEntity.EmployeeId){
      this.payrollEntity.JoinDate = element.JoinDate;
    } 
  });    
  this.minw = new Date(this.payrollEntity.JoinDate);

  if(this.payrollEntity.EmployeeId > 0 && this.payrollEntity.SalaryDate != undefined){
    
    let plBalance = this.CalculateDays(this.payrollEntity.JoinDate, this.payrollEntity.SalaryDate);
    let LeaveTaken = this.payrollEntity.LeaveTaken >= 0 ? this.payrollEntity.LeaveTaken : 0;
    this.payrollEntity.plBalance = plBalance;
    this.payrollEntity.AvailableLeave = plBalance - LeaveTaken;
  }
}


  CalculateDays(firstDate, secondDate) {
    const timeDiff = new Date(secondDate).getTime() - new Date(firstDate).getTime();
    let days = timeDiff / (1000 * 60 * 60 * 24);
    let months = timeDiff / (1000 * 60 * 60 * 24 * 30);
    months = parseInt(months.toString());
months = months + 1;

  if (months == 0 && date > 15) {
    
  } else {
    //months = months + 1;
  }
    let plBalance = 0;
    if (months <= 6) {
      var d = new Date(firstDate);
      var date = d.getDate();
      if (months == 1 && date > 15) {
        plBalance = 0;
      } else {        
        plBalance = 1 * months;
      }
    } else if (months > 6 && months <= 12) {
      plBalance = 6;
      plBalance = plBalance + (1.5 * (months - 6));
    } else if (months > 12 && months <= 36) {
      plBalance = plBalance + 5;
      let leftMonth = months % 12;
      plBalance = plBalance + leftMonth * 1.5;
    } else if (months > 36 && months <= 60) {
      plBalance = plBalance + 6;
      let leftMonth = months % 12;
      plBalance = plBalance + leftMonth * 1.5;
    } else if (months > 60) {
      plBalance = plBalance + 7;
      let leftMonth = months % 12;
      plBalance = plBalance + leftMonth * 1.5;
    }

    return plBalance;
  }

  calculatePayroll() {
    debugger

    this.submitted = false;
    let TotalSalary = +this.payrollEntity.Salary + +this.payrollEntity.Incentive;

    let Tax = 0;
    if (TotalSalary > 0 && TotalSalary < 6000) {
      Tax = 0;
    } else if (TotalSalary >= 6000 && TotalSalary < 9000) {
      Tax = 80;
    } else if (TotalSalary >= 9000 && TotalSalary < 12000) {
      Tax = 150;
    } else if (TotalSalary == 12000 || TotalSalary > 12000) {
      Tax = 200;
    }

    this.payrollEntity.ProfessionalTax = Tax > 0 ? Tax : 0;

    if (this.payrollEntity.Salary > 21000) {
      this.payrollEntity.totalEsic = 0;
      this.payrollEntity.Employer = 0;
      this.payrollEntity.Employee = 0;
    } else {
      this.payrollEntity.Employer = (TotalSalary * 3.25) / 100 > 0 ? (TotalSalary * 3.25) / 100 : 0;
      this.payrollEntity.Employee = (TotalSalary * 0.75) / 100 > 0 ? (TotalSalary * 0.75) / 100 : 0;
      this.payrollEntity.totalEsic = +this.payrollEntity.Employer + +this.payrollEntity.Employee;
    }

    let EarningSalary = +TotalSalary - +this.payrollEntity.totalEsic - +Tax;
    this.payrollEntity.TakeawaySalary = EarningSalary > 0 ? EarningSalary : 0;

    // this.CalculateDays(this.payrollEntity.JoinDate, this.payrollEntity.date);

  }

  addPayroll(payrollForm) {
    debugger
    this.submitted = true;
    if (this.payrollEntity.SalaryMonth != undefined) {
      var d = new Date(this.payrollEntity.SalaryMonth);
      var Month = d.getMonth() + 1;
      // var date = d.getDate();
      var date = 1;
      var Year = d.getFullYear();
      this.payrollEntity.SalaryMonth = Year + "-" + (Month < 10 ? "0" + Month : "" + Month) + "-" + (date < 10 ? "0" + date : "" + date);
      this.salaryMonthValid = false;
    } else {
      this.salaryMonthValid = true;
    }
    if (payrollForm.valid && !this.salaryMonthValid) {
      this.submitted = false;
      this.globals.isLoading = true;
      this.payrollEntity.AvailableLeave = 0;
      this.PayrollService.addPayroll(this.payrollEntity)
        .then((data) => {
          swal({
            type: this.globals.payrollTranslationText.payroll.form.alerts.type,
            title: this.globals.payrollTranslationText.payroll.form.alerts.title,
            text: this.globals.payrollTranslationText.payroll.form.alerts.text,
            showConfirmButton: false,
            timer: 2000
          })
          this.globals.isLoading = false;
          this.router.navigate(['/payroll/list/']);
        },
          (error) => {
            this.globals.isLoading = false;
            this.globals.pageNotfound(error.error.code);
          });
    }
  }

  clearData(payrollForm) {
    this.payrollEntity = {};
    this.payrollEntity = {};
    this.errorEntity = {};
    this.salaryMonthValid = false;
    this.payrollEntity.AvailableLeave = 0;
    this.payrollEntity.plBalance = 0;
    this.payrollEntity.Employee = 0;
    this.payrollEntity.Employer = 0;
    this.payrollEntity.totalEsic = 0;
    this.payrollEntity.ProfessionalTax = 0;
    this.payrollEntity.TakeawaySalary = 0;
    this.submitted = false;
    payrollForm.form.markAsPristine();
  }
}
