import { Component, OnInit } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var $, swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginEntity;
  submitted;
  btn_disable;
  invalid;
  errorEntity;

  constructor(private router: Router, private route: ActivatedRoute, private AuthService: AuthService, public globals: Globals) { }

  ngOnInit() {
    this.loginEntity = {};
    this.errorEntity = {};
  }

  login(loginForm) {

    this.submitted = true;
    if (loginForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;

      if(this.loginEntity.EmailAddress=='admin@gmail.com' && this.loginEntity.Password=='adminpassword'){
        this.globals.authData = '';
        localStorage.removeItem('token');
        localStorage.setItem('token', window.btoa(this.loginEntity.password));
        this.globals.authData = window.btoa(this.loginEntity.password);
        this.router.navigate(['/payroll']);
        this.globals.isLoading = false;
        this.btn_disable = false;
        this.submitted = false;
      } else {
        this.globals.isLoading = false;
        this.btn_disable = false;
        this.submitted = false;
      }

    }
  }

}

