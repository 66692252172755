import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
declare var $, swal: any, PerfectScrollbar;


@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  configurationEntity;
  submitted;
  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private ConfigurationService: ConfigurationService) { }

  ngOnInit(): void {
    this.globals.isLoading = true;
    this.configurationEntity = {};
    this.ConfigurationService.getAll()
      .then((data) => {
        this.configurationEntity = data;
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
              type: this.globals.payrollTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.payrollTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.payrollTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
          })
        });
  }
  updateLeave(configurationForm) {debugger
    this.submitted = false;    
    if (configurationForm.valid) {
      this.globals.isLoading = true;
      this.ConfigurationService.updateLeave(this.configurationEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.submitted = false;
          swal({
              type: this.globals.payrollTranslationText.common.configuration.form.alerts.success.type,
              title: this.globals.payrollTranslationText.common.configuration.form.alerts.success.title,
              text: this.globals.payrollTranslationText.common.configuration.form.alerts.success.text,
              showConfirmButton: false,
              timer: 2000
          })
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.payrollTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.payrollTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.payrollTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

}
