<!-- ########## FOOTER FOR COMMON - LOGGED OUT ########## -->
<footer class="footer_register footerFix bg-dark p-2" *ngIf="globals.authData==undefined">
  <div class="container-fluid">
    <div class="row justify-content-around">
      <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-left"><span class="footer_year text-white">&copy;
        </span>&nbsp;
        <a href="http://www.theopeneyes.com/" target="_blank" class="text-white"> OpenEyes Technologies Inc. </a>
      </div>
      <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right"><a routerLink="/privacyPolicy"
          target="_blank" class="text-white">Privacy Policy</a> | <a routerLink="/cookiePolicy" target="_blank"
          class="text-white">Cookie Policy</a> | <a routerLink="/termsofuse" target="_blank" class="text-white">Terms of
          use</a>
      </div>
    </div>
  </div>
</footer>

<!-- ########## FOOTER FOR COMMON - LOGGED IN ########## -->
<footer class="footer_register footerFix bg-dark p-2" *ngIf="globals.authData!=undefined && globals.currentModule!='admin'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 text-center"><span class="text-white">© </span> <span
          class="footer_year text-white"></span>&nbsp;
        <a href="http://www.theopeneyes.com/" target="_blank" class="text-white">  OpenEyes Technologies Inc.</a>
      </div>
    </div>
  </div>
</footer>

<!-- ########## FOOTER FOR ADMIN - LOGGED IN ########## -->
<footer class="footer_register footerFix bg-dark p-2" *ngIf="globals.authData!=undefined && globals.currentModule=='admin'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-left">© <span
          class="footer_year footer_year"></span>&nbsp;
        <a href="http://www.theopeneyes.com/" target="_blank" class="text-white"> OpenEyes Technologies Inc. </a>
      </div>
      <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right"><a routerLink="/privacyPolicy"
          target="_blank" class="text-white">Privacy Policy</a> | <a routerLink="/cookiePolicy" target="_blank"
          class="text-white">Cookie Policy</a> | <a routerLink="/termsofuse" target="_blank" class="text-white">Terms of
          use</a>
      </div>
    </div>
  </div>
</footer>

