import { Component, OnInit, NgZone } from '@angular/core';
import { Globals } from '../globals';
import { PayrollService } from '../services/payroll.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})


export class ChartComponent implements OnInit {
  defaultEntity;
  payrollEntity;
  errorEntity;
  chartArr;
  private chart: am4charts.XYChart;

  constructor(private zone: NgZone, public globals: Globals, private PayrollService: PayrollService) { }
   
 
  ngOnInit() {
    this.defaultEntity = [];
    this.chartArr = "";
    this.payrollEntity = {};
    this.errorEntity = {};
    this.PayrollService.getDefaultData()
      .then((data) => {
        var defaultdata: any;
            defaultdata =  data;
            console.log(defaultdata);
        var defaultSelect = {
          Id: "",
          Name: this.globals.payrollTranslationText.payroll.form.employeeName.select,
          Value: ""
        };
        this.defaultEntity.push(defaultSelect);
        this.defaultEntity = [...this.defaultEntity, ...defaultdata];
     },
      (error) => {
        this.globals.isLoading = false;
      });
  }

 
  generateChart() {

    this.payrollEntity.EmployeeId = '1';
    this.payrollEntity.FromDate = '2020-4-30';
    this.payrollEntity.ToDate = '2020-7-30';

    this.PayrollService.generateChart(this.payrollEntity)
      .then((data) => {
        this.chartArr = data;
        this.showChart();
     },
      (error) => {
        this.globals.isLoading = false;
      });

  
  }

  showChart() {
    if(this.chartArr.length > 0) {
      this.zone.runOutsideAngular(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.data = this.chartArr;
        
        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Month";
        categoryAxis.title.text = "Month";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;
        
        let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = "Salary (Rs)";
        
        // Create series
        function createSeries(field, name, stacked) {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.valueY = field;
          series.dataFields.categoryX = "Month";
          series.name = name;
          series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
          series.stacked = stacked;
          series.columns.template.width = am4core.percent(95);
        }
        
        createSeries("Employer", "Employer", true);
        createSeries("TakeawaySalary", "TakeawaySalary", true);
        createSeries("Employee", "Employee", true);
        createSeries("ProfessionalTax", "ProfessionalTax", true);
        
        // Add legend
        chart.legend = new am4charts.Legend();
        
  
        // this.chart = chart;
      });
    } else {
       alert("No Record Found");
    }
  
  }
  
 

}
