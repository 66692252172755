<div class="employeeFormMain">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="formMain">
                    <h2>{{globals.payrollTranslationText.common.configuration.form.title}}</h2>
                    <form class="form-validate p-3" role="form" #configurationForm="ngForm"
                        (ngSubmit)="updateLeave(configurationForm)">
                        <ng-container *ngFor="let leaveday of configurationEntity.LeaveDay; let i = index">
                        <div class="form-group">
                            <label
                                for="e_Name">{{globals.payrollTranslationText.common.configuration.form.leave[leaveday.Key]}}</label>
                            <input type="text" class="form-control" #leave="ngModel" [(ngModel)]="leaveday.Value"
                            name="leave{{i}}" pattern="^[0-9.]{0,}"
                            
                                [ngClass]="{error: (leave.dirty && leave.invalid) || (leave.pristine && submitted)}"
                                maxlength="50" required>
                              <span class="error_span error"
                                        *ngIf="(leave.dirty && leave.invalid) || (leave.pristine && submitted)">
                                        <span *ngIf="leave.errors && leave.errors.required">
                                            {{globals.payrollTranslationText.common.configuration.form.require}}
                                        </span>
                                        <span *ngIf="leave.errors && leave.errors.pattern">
                                            {{globals.payrollTranslationText.common.configuration.form.pattern}}
                                        </span>
                                    </span>
                        </div>
                        </ng-container>
                        <button type="submit" class="btn btn-primary">{{globals.payrollTranslationText.common.configuration.form.buttons.submit}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
